@font-face {
  font-family: 'Font Fabric Intro';
  src: local('Fontfabric Intro Regular'), local('Fontfabric-Intro-Regular'),
  url('./../../fonts/font_fabric/Intro-Regular.woff2') format('woff2'),
  url('./../../fonts/font_fabric/Intro-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Font Fabric Intro Light';
  src: local('Fontfabric Intro Thin'), local('Fontfabric-Intro-Thin'),
  url('./../../fonts/font_fabric/Intro-Light.woff2') format('woff2'),
  url('./../../fonts/font_fabric/Intro-Light.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Font Fabric Book';
  src: local('Font Fabric Book'), local('Intro-Book'),
  url('./../../fonts/font_fabric/Intro-Book.woff2') format('woff2'),
  url('./../../fonts/font_fabric/Intro-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('./../../fonts/fontawesome/fontawesome-webfont.woff2') format('woff2'),
       url('./../../fonts/fontawesome/fontawesome-webfont.woff') format('woff'),
       url('./../../fonts/fontawesome/fontawesome-webfont.ttf') format('truetype'),
       url('./../../fonts/fontawesome/fontawesome-webfont.eot') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}