@import "../base";
@import "../_includes/mixins";

.row.top-bar {
  background-color: #299DBE;
  margin: 0px;

  .top-text {
    text-align: right;
    width: 100%;
    font-size: 12px;
    text-transform: uppercase;
    padding: 5px 30px 5px 0px;

    span{
      padding: 10px;
      color: #ffffff;

      @include media-breakpoint-down(md) {
        float: left;
        padding: 0px 5px 0px 10px !important;
        margin: 0px;
      }

      a{
        color:#ffffff;
      }
      a:hover{
        text-decoration: underline;
      }
    }
  }
}

header {
  .header-wrapper {
    position: relative;
    width: 100%;
    z-index: 100;
    height: 70px;
    background-color: rgba(255, 255, 255, 0.75);

    .logo {
      position: absolute;
      height: 50px;
      display: block;
      width: 182px;
    }
  }

  nav#nav_main {
    margin-left: 120px;

    @include media-breakpoint-down(lg) {
      margin-left: 82px;
    }
  }

  ul.nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    list-style-type: none;
    float: right;
    position: relative;
    margin-top: 25px;

    @include media-breakpoint-down(md) {
      display: none;
      width: 100%;
      background-color: #fff;
      margin-top: 70px;
      position: absolute;
      padding-left: 0px;
      left: 0;
    }

    li {
      padding: 0px 20px 0px 20px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      border-right: 1px solid #16608A;

      @include media-breakpoint-down(md) {
        border: 0px;
      }

      a {
        color: #16608A;
        display: block;

        @include media-breakpoint-down(md) {
          .menu-text {
            padding-bottom: 10px;
          }

        }
      }
    }

    li.menu-item-has-children {

      ul{
        display: none;
      }
    }

    @include media-breakpoint-down(md) {
      li.menu-item-has-children::after {
        content: ' ';
        position: absolute;
        left: 22%;
        top: 18%;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid #16608a;
        clear: both;
      }
    }
    li.menu-item-has-children.open{
      ul{
        display: block;
        position: absolute;
        background-color: #fff;
        border: 0px;
        width: 1100px;
        left: 0;
        margin-top: 24px;
        padding-left: 0px;
        margin-left: -115px;


        @include media-breakpoint-down(lg) {
          width: 100%;
          margin-left: 0px;
        }

        @include media-breakpoint-down(md) {
          left: 0;
          margin-top: 0px;
          border-top: 1px solid #cccccc;
        }

        li{
          width: 25%;
          float: left;
          border: 0px;
          padding: 0px;

          @include media-breakpoint-down(md) {
            width: 100%;
          }

          span.nav-link-image {
            width: 100%;
            display: block;
            margin-left: auto;
            margin-right: auto;

            img {
              display: block;
              margin-left: auto;
              margin-right: auto;
              width: 150px;
              padding: 20px 20px 20px 20px;
            }
          }
          .menu-text {
            padding: 20px 10px 30px 20px;
            height: 133px;

            @include media-breakpoint-down(lg) {
              min-height: 155px;
            }

            .nav-link-description{
              font-family: Font Fabric Intro Light;
              font-weight: bolder;
              text-transform: none;
              font-size: 0.9rem;
            }
          }

          a{
            color: #FFFFFF;
          }
        }
        li:nth-child(1){
          background-color: #40ACB6;

          span.nav-link-image {
            background-color: #ffffff;
          }
        }
        li:nth-child(2){
          background-color: #c3b7ab;
        }
        li:nth-child(3){
          background-color: #f1c367;

          span.nav-link-image {
            background-color: #ffffff;
          }
        }
        li:nth-child(4){
          background-color: #16608a;
        }
        li:nth-child(5) {
          background-color: #f1c367;
        }
        li:nth-child(6){
          background-color: #16608a;

          span.nav-link-image {
            background-color: #ffffff;
          }
        }
        li:nth-child(7){
          background-color: #40ACB6;
        }
        li:nth-child(8){
          background-color: #c3b7ab;

          span.nav-link-image {
            background-color: #ffffff;
          }
        }
      }
    }

    li:last-child {
      border: 0px;
    }
  }
}
div#nav_toggle {
  float: right;
  padding-top: 25px;
}
i.fa.fa-bars {
  color: #16608a;
  font-size: 20px;
}
.fa, .fab{
  font: normal normal normal 14px/1 FontAwesome;
}

.fa.fa-bars::before {
  content: "\f0c9";
}

.fa.fa-facebook-official::before {
  content: "\f082";
}

.fa.fa-twitter-square::before {
  content: "\f081";
}

.fa.fa-linkedin-square::before {
    content: "\f08c";
}

@include media-breakpoint-down(md) {
  .nav-toggle-active ul.nav {
    display: block;
    border-bottom: 1px solid #b5b5b5;
    border-top: 1px solid #b5b5b5;
    padding-top: 10px;
  }
}
