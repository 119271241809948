@import "../base";
.row.footer{
  width: 100%!important;
}
.footer-widgets {
  color: #16608a;
  background-color: #e7e2dc;
  font-size: 17px;

  @include media-breakpoint-up(md) {
    margin-top: -170px;
    background-color: transparent;
    background-image: url(/assets/src/img/footer-background.png);
    background-size: cover;
    background-position: center;
    padding-top: 25px;
  }

  ul{
    @include media-breakpoint-up(md) {
      padding: 275px 5px 5px 5px;
    }
    padding-top: 30px;
    padding-left: 10px;
  }

  li{
    list-style: none;
  }

  a {
    color: #16608a;
  }
  a:hover{
    text-decoration: underline;
  }
}

@include media-breakpoint-up(md) {
  .zkn-widget-body .fractional_number sup {
    line-height: 80px !important;
  }
  .zkn-widget-type-3 {
    margin-top: 180px;
  }
  .zkn-widget-body {
    margin-left: 30px;
  }
}
.zkn-widget-type-3 .zkn-widget-body{
  height: 100%!important;
  margin-bottom: 30px;
}
.zkn-widget-type-3 {
  width: 100%!important;
}
