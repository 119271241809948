$font-family-base: 'Font Fabric Intro';
$font-size-base: 1.125rem;

$body-color: #515050;

$link-color: $corp-color;

$link-hover-color: rgba($corp-color, .8);
$link-hover-decoration: none;


//$input-btn-padding-x: 1.75rem;
//$input-btn-padding-y: .75rem;
//$input-btn-padding-x-sm: 2rem;
//$input-btn-padding-y-sm: .375rem;
//$input-btn-focus-box-shadow: none;
//
$headings-font-weight: 600;
//$headings-line-height: 1.252;
//$headings-margin-bottom: 2rem;
//
//$font-size-sm: .875rem;
//
//$h1-font-size: $font-size-base * 2 !default;
