.fl-row-content-wrap {
  padding: 50px 20px;
}

.fl-rich-text {
  line-height: 2.5rem;

  @include cvz-highlight();
}

.fl-icon-wrap {
  display: block;

  margin-top: 10px;
  text-align: center;

  .fl-icon {
    display: block;

    font-size: 2.5rem;

    .fa,
    [class^="icon-"] {
      float: none;
      font-size: inherit !important;

      &::before {
        font-size: inherit !important;
      }
    }
  }

  .fl-icon-text {
    height: auto !important;
    display: block;
    text-align: center;
    padding-left: 0;

    h3 {
      font-size: 1.375rem;
      font-weight: 500;
    }

    p {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}

.fl-accordion-item {
  border: 1px solid $color-grey;

  @include cvz_border_radius();

  margin-bottom: 1.25rem;
  background-color: #f4f4f4;

  .fl-accordion-button {
    &::before {
      content: ' ';
      position: absolute;

      bottom: 0;
      left: 15px;

      width: 20px;
      height: 4px;
      opacity: 0;

      transition: all .15s linear;

      background-color: $corp-color;
    }

    &::after {
      content: ' ';
      position: absolute;

      bottom: 0;
      left: 40px;

      width: 20px;
      height: 4px;
      opacity: 0;

      transition: all .15s linear;

      background-color: $color-grey;
    }
  }

  .fl-accordion-button-label {
    color: rgba($body-color, 0.8);

    @include cvz_transition_link();
  }

  .fl-accordion-button-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 50px;
    background-color: $color-grey;
    @include cvz_transition_bg();

    .fa {
      color: rgba($body-color, 0.8);
    }
  }

  &.fl-accordion-item-active {
    .fl-accordion-button {
      position: relative;

      &::after,
      &::before {
        opacity: 1;
      }
    }

    .fl-accordion-button-label {
      color: $body-color;
    }

    .fl-accordion-button-toggle {
      background-color: $corp-color;

      @include cvz_border_radius(false, false, false);

      .fa {
        color: #ffffff;
      }
    }
  }

  .fl-accordion-content {
    font-size: 0.875rem;
    line-height: 1.375rem;

    p {
      margin-bottom: 5px;
    }
  }
}

.cvz-post-grid {
  .cvz-post-grid-post {
    position: relative;

    @include cvz_border_radius();

    .cvz-post-grid-date {
      position: absolute;
      display: flex;
      flex-direction: column;

      z-index: 10;

      background-color: #ffffff;
      align-items: center;

      top: 18px;
      right: 18px;

      width: 42px;
      height: 50px;

      @include cvz_border_radius(false, true, false, false, 3px);

      span {
        &.day {
          display: flex;
          font-size: 1.25rem;
          font-weight: 600;

          height: 34px;
          align-items: center;
        }

        &.month-year {
          display: flex;
          background-color: $corp-color;
          font-size: 0.55rem;
          font-weight: 600;
          color: #ffffff;
          text-transform: uppercase;

          width: 100%;
          height: 16px;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .cvz-post-grid-meta {
      display: flex;

      font-size: 0.6875rem;
      font-weight: 500;
      color: rgba($body-color, .5);

      margin: 0 .2rem 1rem .2rem;

      i[class^="icon-"] {
        margin-right: .4rem;
      }

      a {
        color: inherit;

        @include cvz_transition_link();

        &:hover {
          color: $color-dark-grey;
        }
      }

      .cvz-post-grid-category {

      }

      .cvz-post-grid-author {
        margin-left: auto;
      }
    }

    .cvz-post-grid-image {
      padding: 15px 15px 0 15px;

      img {
        @include cvz_border_radius();
      }
    }

    .cvz-post-grid-title {
      font-size: 1.125rem;
      color: $body-color;
      margin-bottom: 0.625rem;

      a {
        color: inherit;

        @include cvz_transition_link();

        &:hover {
          color: $corp-color;
        }
      }
    }

    .cvz-post-grid-content {
      font-size: .875rem;
      line-height: 1.625rem;
    }

    .cvz-post-grid-more {
      display: block;
      font-size: 0.8125rem;
      font-weight: 600;
      color: $body-color;
      text-align: right;

      margin-top: 1.5rem;

      text-transform: uppercase;

      @include cvz_transition_link();

      &:hover {
        color: $corp-color;
      }

      i {
        font-size: 1rem;
        margin-left: .5rem;
        color: $corp-color;
      }
    }
  }
}

.fl-module-cvz-testimonials {
  .fl-testimonials-wrap {
    &.fl-testimonials-align-center {
      text-align: center;
    }

    &.fl-testimonials-align-right {
      text-align: right;
    }

    .fl-testimonial {
      font-size: 1.125rem;
      line-height: 2.25rem;

      p {
        @include media-breakpoint-up(xl) {
          max-width: 40%;
        }

        margin: 0 auto;
      }

      .fl-testimonial-author {
        font-weight: 600;

        margin-top: 0.5rem;
      }
    }

    .bx-pager.bx-default-pager a {
      @include cvz_transition_link();
      @include cvz_border_radius(false, true, false, true, 2.5px);

      background-color: $color-grey;
      opacity: 1;

      &:hover,
      &.active {
        background-color: $corp-color;
      }
    }
  }
}

.cvz-cta-container {
  border-top: 5px solid $corp-color;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.11);
  background: #ffffff;
  padding-top: 5px;

  @include cvz_border_radius(false, false, true, true);

  .cvz-cta-container-inner {
    padding: 2.25rem 0;

    .cvz-cta-col {
      margin-bottom: 1rem;
      padding-top: 1rem;

      border-top: 1px solid $color-grey;

      &:first-of-type {
        border-top: none;
        padding-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        padding-top: 0;
        border-top: none;
      }

      position: relative;
      text-align: center;

      padding-right: 15px;
      padding-left: 15px;

      h2 {
        font-size: 1rem;
        text-transform: uppercase;

        margin-bottom: 0.75rem;
      }

      p {
        font-size: 0.875rem;
        line-height: 1.5rem;

        color: rgba(0, 0, 0, 0.6);

        margin-bottom: 1.75rem;

        @include media-breakpoint-up(lg) {
          margin-left: 1rem;
          margin-right: 1rem;
        }
      }

      a {
        font-size: 0.875rem;
        color: $body-color;
        font-weight: 600;
        text-transform: uppercase;

        i {
          padding-left: 0.5rem;
          color: $corp-color;
        }
      }

      form {
        display: flex;

        justify-content: center;

        input[type="text"] {
          border-radius: 0 0 0 5px;
          outline: 0;
          background-color: transparent;
          color: $body-color;
          padding: .3rem .5rem;
          width: 250px !important;
          border-right: 0;
        }

        input,
        button {
          margin: 0;
          padding: 0;
          border: 1px solid $color-grey;
          font-size: .875rem;
        }

        input[type="submit"],
        button[type="submit"] {
          border-radius: 0 5px 0 0;

          @include cvz_transition_bg();

          outline: 0;
          background-color: $corp-color;
          border-color: $corp-color;
          color: #ffffff;
          font-weight: 500;
          text-transform: uppercase;
          padding: .3rem .5rem;
          line-height: 1;
          cursor: pointer;

          &:hover {
            background-color: rgba($corp-color, 0.9);
          }
        }
      }

      .cvz-cta-col-inner {
        @include media-breakpoint-up(md) {
          &::after {
            position: absolute;
            content: ' ';
            border-right: 1px solid $color-grey;

            width: 1px;
            height: 100%;

            top: 0;
            right: 0;
          }
        }
      }

      &:last-child {
        .cvz-cta-col-inner {
          &::after {
            border-color: transparent;
          }
        }
      }
    }
  }
}

.cvz-mcw-container {
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.18);
  background: #ffffff;
  padding-top: 5px;

  .cvz-mcw-container-inner {
  }

  @include cvz_border_radius(false, false, true, true);

  .cvz-mcw-col {
    float: left;
  }
}

.fl-module-cvz-numbered-list {
  margin-top: 1.75rem;

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  ol {
    margin: 0;
    padding: 0;

    list-style: none;

    li {
      margin-bottom: 1.75rem;

      span {
        position: relative;
        display: flex;

        align-items: center;
        font-weight: 500;

        &::before {
          display: flex;

          margin-right: 1rem;

          width: 36px;
          height: 36px;

          font-size: 1rem;
          font-weight: 600;

          justify-content: center;
          align-items: center;

          content: attr(data-index);

          @include cvz-border_radius;
          @include cvz_transition_link;

          background: $color-grey;
          color: $body-color;
        }
      }

      a {
        color: $body-color;

        &:hover {
          span {
            &::before {
              background: $corp-color;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}