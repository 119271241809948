@import "base";

@import "_includes/config";

@import "_components/beaver-builder";
@import "_components/buttons";
@import "_components/header";
@import "_components/footer";
@import "_components/footer-bottom";

html {
  -webkit-font-smoothing: antialiased;
}
main#content {
  position: relative;
  z-index: 2;
}

.nav-link-text {
  white-space: nowrap;
}

a {
  color: #299dbe;

  &:hover {
    color: #299dbe;
    opacity: 0.9;
  }
}

p{
  font-family: Font Fabric Book;
  font-size: 16px;
  color: #000000;
}
h6{
  font-family: Font Fabric Intro;
  font-size: 18px!important;
  font-weight: 400;
  line-height: 1.5rem;
  color: #16608a;
  padding-bottom: 10px;
}

.fl-rich-text {
  font-family: Font Fabric Book;

  h6{
    span {
      font-size: 18px!important;
    }
  }

  ul {
    color: #000!important;
  }

  strong {
    font-weight: bolder!important;
  }

  a {
    color: #299dbe;

    &:hover {
      opacity: 0.9;
    }
  }
}

.home-box .fl-row-content-wrap{
  -webkit-box-shadow: 0px 2px 12px -2px rgba(191,191,191,1);
  -moz-box-shadow: 0px 2px 12px -2px rgba(191,191,191,1);
  box-shadow: 0px 2px 12px -2px rgba(191,191,191,1);
}
.home .fl-post-column:nth-child(1) .fl-post-grid-text{
  background-color: #40acb6;
  color: #ffffff!important;

  p{
    color: #ffffff!important;
  }
}
.home .fl-post-column:nth-child(2) .fl-post-grid-text{
  background-color: #c3b7ab;
  color: #ffffff!important;

  p{
    color: #ffffff!important;
  }
}
.home .fl-post-column:nth-child(3) .fl-post-grid-text{
  color: #ffffff!important;
  background-color: #16608a;

  p{
    color: #ffffff!important;
  }
}
.fl-module.fl-module-heading.fl-node-5c863cfce7317{
  font-family: Font Fabric Intro Light;
  font-weight: bolder;
  font-size: 2.5rem!important;
}
a.fl-post-grid-more {
  font-size: 16px!important;
  font-weight: 600;
}
.home .fl-post-grid-text{

  h2.fl-post-grid-title {
    text-transform: uppercase;

  }

  a{
    color: #ffffff;
  }
}

.fl-post-grid-text{

  h2.fl-post-grid-title {
    text-transform: uppercase;
  }
  a{

    color: #16608a;
  }
}

.fl-col.fl-node-5c863cee3a9a5.fl-col-small .fl-col-content {
  border-right: 1px solid #40acb6;

  @include media-breakpoint-down(md) {
    border: 0px;
  }
}
.fl-col.fl-node-5c863cee3a9aa.fl-col-small .fl-col-content {
  border-right: 1px solid #40acb6;

  @include media-breakpoint-down(md) {
    border: 0px;
  }
}
.fl-rich-text {
  line-height: 1.5rem;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
.contact-text{
  color: #ffffff!important;
  font-size: 1rem;

  a{
    color: #ffffff!important;
  }
  a:hover{
    text-decoration: underline;
  }
  p{
    color: #ffffff !important;
  }
}
.contact-form{
  color: #ffffff;
}
iframe{
  margin-bottom: -22px;
}
.post-text-blue{
 color: #16608a;
}
.post-head {
  padding-bottom: 30px;
  padding-left: 20px;

  .post-title {
    background-color: #40acb6;
    color: #fff;
    padding: 50px;
    font-family: Font Fabric Intro Light;
    font-weight: bolder;

    @include media-breakpoint-down(md) {
      padding: 20px;

      h1{
        font-size: 1.5rem;
      }
    }

    a {
      color: #ffffff;
    }
  }

  .post-category {
    li {
      float: left;
      padding-top: 20px;
      padding-bottom: 40px;
      padding-right: 20px;
      font-size: 14px;

      span {
        font-family: Font Fabric Intro Light;
        font-weight: bolder;
      }
    }

    li:first-child {
      list-style-type: none;
    }
  }

  .post-social {
    position: absolute;
    bottom: 0;
    padding-bottom: 30px;

    a {
      i {
        font-size: 20px;
      }
    }
  }

  .post-featured-image {
    padding: 0px;

    img{
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      overflow: hidden;
      img {
        height: auto;
        position: absolute;
        min-height: 360px;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .container.post-head {
    padding: 0px;

    .row.col-12{
      padding: 0px;
      margin: 0px;
    }
  }
}


.post-text{
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  padding-bottom: 200px;
  line-height: 1.5rem;

  @include media-breakpoint-down(md) {
    width: 100%;
    margin-top: 20px;
  }
}
.contact-text-small{
  font-family: Font Fabric Intro Light;
  font-weight: bolder;
  color: #ffffff;
  font-size: 1rem;

  p{
    color: #ffffff !important;
  }
}
h2.fl-heading{
  font-family: Font Fabric Intro Light;
  font-weight: bolder;
}
i.fi-social-linkedin::before {
  font-size: 25px!important;
  padding-top: 5px;
}
i.fab.fa-youtube:before {
  font-size: 24px!important;
}

@media (min-width: 768px) {
  .home {
    .fl-post-grid-image {
      position: relative;
      overflow: hidden;
      height: 180px;
    }

    .fl-post-grid-text {
      height: 190px;
    }

    a.fl-post-grid-more {
      position: absolute;
      bottom: 15px;
    }
  }
}


@include media-breakpoint-up(md) {
  .vingerprint {
    display: block;
    position: fixed;
    top: 35%;
    left: 0;
    z-index: -1;

    img {
      width: 50%;
      display: block;
    }
  }
}

@include media-breakpoint-down(md) {
  .vingerprint {
    display: none;
  }
}

.home-box {
  position: relative;
  display: block;
  top: -130px;

  @include media-breakpoint-down(md) {
    top: 0px;
  }
}

.home-head-light{
  font-family: Font Fabric Intro Light;
  font-weight: bolder;
}

.fl-module img {
  height: 100%;
}

.f1-icon a i{
  color: #ffffff;
  padding-right: 5px;
}

.fl-icon i {
  float: left;
  height: auto;
  color: #ffffff;
  font-size: 21px;
  width: auto;
  padding-right: 10px;
}

.page-not-found {
  text-align: center;
  height: 440px;
  padding-top: 100px;

  h2{
    font-size: 5rem;
  }
}

th {
  background-color: #e7e2dc;
  padding: 10px;

  @include media-breakpoint-down(sm) {
    padding: 5px;
  }
}
td{
  padding: 0px 10px 0px 10px;

  @include media-breakpoint-down(sm) {
    padding: 0px 5px 0px 5px;
  }
}

table{
  width: 100%;
  margin-bottom: 150px;
}