$corp-color: #f7882f;
$corp-secondary-color: #1a304f;

$corp-font-family: 'Font Fabric Intro';

$color-dark-grey: #565555;
$color-grey: #e1e0e2;

$navigation-link-color: #6e6e6e;
$navigation-link-hover-color: #000000;

$border-radius-default: 0 5px 0 5px;

$colors: (
  'orange': $corp-color,
  'dark-blue': $corp-secondary-color,
  'dark-grey': $color-dark-grey,
  'grey': $color-grey
);